import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CssBaseline,
  ThemeProvider,
  createTheme,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import Loader from "../component/Loader";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import LoginDialog from "../component/LoginModal";
import { BASE_URL } from "../utils/server";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import AddUserDialog from "../component/AddUserDialog";
import EditUserDialog from "../component/EditUserDialog";

const useStyles = makeStyles({
  //   dateField: {
  //     "& input[type='date']::-webkit-calendar-picker-indicator": {
  //       filter: "invert(1)", // Makes the icon white
  //     },
  //   },
});

const options = {
  dimensions: [
    "date",
    "widgetId",
    "deviceType",
    "subId",
    "countryIso",
    "widgetName",
  ],
  metrics: [
    "adRequests",
    "impressions",
    "visibilityRate",
    "clicks",
    "wages",
    "cpm",
    "vCpm",
    "eCpm",
    "cpc",
    "ctr",
  ],
};

let abbr = {
  date: "Date",
  widgetId: "Widget ID",
  deviceType: "Device Type",
  subId: "Sub Id",
  countryIso: "Country ISO",
  widgetName: "Widget Name",
  domain: "Domain",
  adRequests: "Ad Requests",
  impressions: "Impressions",
  visibilityRate: "Visibility Rate",
  clicks: "Clicks",
  wages: "Wages",
  cpm: "CPM",
  vCpm: "vCPM",
  eCpm: "eCPM",
  cpc: "CPC",
  ctr: "CTR",
};

const AdminPanel = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);

  useEffect(() => {
    if (
      !localStorage.getItem("adsToken") ||
      localStorage.getItem("adsRole") === "P"
    ) {
      navigate("/");
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios(`${BASE_URL}fetchUser`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adsToken")}`,
        },
      });
      setData(response.data.data.users);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const deleteUser = async (id) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(
        `${BASE_URL}deleteUser/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adsToken")}`,
          },
        }
      );
      fetchData();
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const confirmDialog = (id) => {
    confirmAlert({
      title: "Delete User",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUser(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const theme = createTheme({
    palette: {
      mode: "light", // Light mode for a white background
      primary: {
        main: "#1976d2", // Blue color for primary elements
      },
      background: {
        default: "#EDEDED", // Light background color
        paper: "#EDEDED", // White background for paper components
      },
    },
    typography: {
      h3: {
        fontWeight: "bold",
        color: "#1976d2", // Blue color for headers
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer autoClose={1000} />
      <Loader
        visible={isLoading}
        onClose={() => {
          setIsLoading(false);
        }}
      />
      <AddUserDialog
        open={addUserModal}
        onClose={() => {
          setAddUserModal(false);
          fetchData();
        }}
      />
      <EditUserDialog
        open={editUserModal}
        onClose={() => {
          setEditUserModal(false);
          fetchData();
        }}
        userData={selectedData}
      />
      <CssBaseline />
      <Container sx={{ marginTop: 5 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setAddUserModal(true);
          }}
          style={{
            float: "right",
            marginBottom: 15,
          }}
        >
          <AddIcon />
          Add User
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#1976d2", fontWeight: "bold" }}>
                  Email
                </TableCell>
                <TableCell style={{ color: "#1976d2", fontWeight: "bold" }}>
                  Name
                </TableCell>
                <TableCell style={{ color: "#1976d2", fontWeight: "bold" }}>
                  Role
                </TableCell>
                <TableCell style={{ color: "#1976d2", fontWeight: "bold" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell key={index} style={{ color: "#000" }}>
                    {row.email}
                  </TableCell>
                  <TableCell key={index} style={{ color: "#000" }}>
                    {row.fullname}
                  </TableCell>
                  <TableCell key={index} style={{ color: "#000" }}>
                    {row.role}
                  </TableCell>
                  <TableCell
                    key={index}
                    style={{
                      color: "#000",
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <EditIcon
                      onClick={() => {
                        setSelectedData(row);
                        setEditUserModal(true);
                      }}
                    />
                    <DeleteIcon
                      onClick={() => {
                        setSelectedData(row);
                        confirmDialog(row?._id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </ThemeProvider>
  );
};

export default AdminPanel;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { BASE_URL } from "../utils/server";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import Loader from "./Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const AddUserDialog = ({ open, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [domains, setDomains] = useState([{ url: "", margin: 0 }]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleAddDomain = () => {
    setDomains([...domains, { url: "", margin: 0 }]);
  };

  const handleRemoveDomain = (index) => {
    setDomains(domains.filter((_, i) => i !== index));
  };

  const handleDomainChange = (index, field, value) => {
    const updatedDomains = domains.map((domain, i) =>
      i === index ? { ...domain, [field]: value } : domain
    );
    setDomains(updatedDomains);
  };

  const handleAddUser = () => {
    setIsLoading(true);
    axios
      .post(
        `${BASE_URL}addUser`,
        {
          email,
          password,
          fullname,
          domain: domains,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adsToken")}`,
          },
        }
      )
      .then((res) => {
        toast.success("User added successfully");
        setEmail("");
        setPassword("");
        setFullname("");
        setDomains([{ url: "", margin: 0 }]);
        setTimeout(() => {
          setIsLoading(false);
          onClose();
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response?.data?.message || "Failed to add user");
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Loader visible={isLoading} onClose={() => setIsLoading(false)} />
      <ToastContainer autoClose={1000} />
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Full Name"
          fullWidth
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {domains.map((domain, index) => (
          <div
            key={index}
            style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}
          >
            <TextField
              label="Domain URL"
              fullWidth
              value={domain.url}
              onChange={(e) => handleDomainChange(index, "url", e.target.value)}
            />
            <TextField
              label="Margin"
              type="number"
              fullWidth
              value={domain.margin}
              onChange={(e) => {
                if (e.target.value <= 100) {
                  handleDomainChange(
                    index,
                    "margin",
                    e.target.value ? Number(e.target.value) : ""
                  );
                }
              }}
            />
            <IconButton onClick={() => handleRemoveDomain(index)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}

        <Button onClick={handleAddDomain} variant="outlined">
          Add Domain
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleAddUser}
          disabled={!email || !password || !fullname}
        >
          Add User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../utils/server";
import { toast, ToastContainer } from "react-toastify";
import Loader from "./Loader";

const LoginDialog = ({ open, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    // onLogin({ email, password });
    setIsLoading(true);
    axios
      .post(`${BASE_URL}signIn`, {
        email,
        password,
      })
      .then((res) => {
        console.log(res.data.data);
        localStorage.setItem("adsToken", res.data.data.token);
        localStorage.setItem(
          "adsRole",
          res.data.data.role === "ADMIN" ? "A" : "P"
        );
        setEmail("");
        setPassword("");
        toast.success("Login Successfully");
        setTimeout(() => {
          setIsLoading(false);
          onClose();
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Loader
        visible={isLoading}
        onClose={() => {
          setIsLoading(false);
        }}
      />
      <ToastContainer autoClose={1000} />
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleLogin} disabled={!email || !password}>
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;

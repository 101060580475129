import React from 'react';
import { CircularProgress, Backdrop, Box } from '@mui/material';

const Loader = ({ visible, onClose }) => {
  return (
    <Backdrop open={visible} onClick={onClose} sx={{ color: '#fff', zIndex: 9999 }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Box>
    </Backdrop>
  );
};

export default Loader;

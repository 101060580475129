import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import LoginDialog from "./LoginModal";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginModal, setLoginModal] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <LoginDialog
        open={loginModal}
        onClose={() => {
          setLoginModal(false);
        }}
      />
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Clickbyte
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="user"
          onClick={handleMenuClick}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {localStorage.getItem("adsToken") ? (
            <>
              {localStorage.getItem("adsRole") === "A" && (
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                  onClick={() => {
                    if (window.location.pathname === "/admin") {
                      navigate("/");
                    } else {
                      navigate("/admin");
                    }
                    handleMenuClose();
                  }}
                >
                  {window.location.pathname === "/admin" ? (
                    <>
                      <PersonIcon />
                      Admin Panel
                    </>
                  ) : (
                    <>
                      <AdminPanelSettingsIcon />
                      Admin Panel
                    </>
                  )}
                </MenuItem>
              )}
              <MenuItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                onClick={() => {
                  handleMenuClose();
                  localStorage.removeItem("adsToken");
                  localStorage.removeItem("adsRole");
                  navigate("/")
                }}
              >
                <LogoutIcon />
                Logout
              </MenuItem>
            </>
          ) : (
            <MenuItem
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={() => {
                handleMenuClose();
                setLoginModal(true);
              }}
            >
              <LoginIcon />
              Login
            </MenuItem>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

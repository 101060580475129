import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CssBaseline,
  ThemeProvider,
  createTheme,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import Loader from "../component/Loader";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import LoginDialog from "../component/LoginModal";
import { BASE_URL } from "../utils/server";

const useStyles = makeStyles({
  //   dateField: {
  //     "& input[type='date']::-webkit-calendar-picker-indicator": {
  //       filter: "invert(1)", // Makes the icon white
  //     },
  //   },
});

const options = {
  dimensions: [
    "date",
    "widgetId",
    "deviceType",
    "subId",
    "countryIso",
    "widgetName",
  ],
  metrics: [
    "adRequests",
    "impressions",
    "visibilityRate",
    "clicks",
    "wages",
    "cpm",
    "vCpm",
    "eCpm",
    "cpc",
    "ctr",
  ],
};

let abbr = {
  date: "Date",
  widgetId: "Widget ID",
  deviceType: "Device Type",
  subId: "Sub Id",
  countryIso: "Country ISO",
  widgetName: "Widget Name",
  domain: "Domain",
  adRequests: "Ad Requests",
  impressions: "Impressions",
  visibilityRate: "Visibility Rate",
  clicks: "Clicks",
  wages: "Revenue",
  cpm: "CPM",
  vCpm: "vRPM",
  eCpm: "eCPM",
  cpc: "CPC ¢",
  ctr: "CTR (%)",
};

const UserPanel = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const handleSubmit = async () => {
    if (!localStorage.getItem("adsToken")) {
      toast.error("Please Login First");
      setLoginModal(true);
      return;
    }
    setIsLoading(true);
    if (!startDate) {
      alert("Please enter Start Date");
      setIsLoading(false);
      return;
    }

    if (!endDate) {
      alert("Please enter End Date");
      setIsLoading(false);
      return;
    }

    // if (selectedDimensions.length === 0) {
    //   alert("Please Select Dimensions");
    //   setIsLoading(false);
    //   return;
    // }

    if (selectedMetrics.length === 0) {
      alert("Please Select Metrics");
      setIsLoading(false);
      return;
    }

    try {
      let response = await axios(
        `${BASE_URL}fetchReport?startDate=${startDate}&endDate=${endDate}&dimensions=,${selectedDimensions.join(
          ","
        )}&metrics=${selectedMetrics.join(",")}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adsToken")}`,
          },
        }
      );
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      if (
        error?.response?.data?.error
      ) {
        console.log(error?.response?.data?.error.includes("VALIDATION_INCORRECT_DATE_END"))
        toast.error("Invalid Date End");
      } else {
        toast.error(error.response.data.message);
      }
      setIsLoading(false);
    }
  };

  const theme = createTheme({
    palette: {
      mode: "light", // Light mode for a white background
      primary: {
        main: "#1976d2", // Blue color for primary elements
      },
      background: {
        default: "#EDEDED", // Light background color
        paper: "#EDEDED", // White background for paper components
      },
    },
    typography: {
      h3: {
        fontWeight: "bold",
        color: "#1976d2", // Blue color for headers
      },
    },
  });

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <LoginDialog
        open={loginModal}
        onClose={() => {
          setLoginModal(false);
        }}
      />
      <ToastContainer autoClose={1000} />
      <Loader
        visible={isLoading}
        onClose={() => {
          setIsLoading(false);
        }}
      />
      <CssBaseline />
      <Container sx={{ marginTop: 5 }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            flexDirection: "row",
            margin: "10px 0px 20px 0px",
          }}
        >
          <TextField
            className={classes.dateField}
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            variant="outlined"
            color="primary"
            InputProps={{
              style: { color: "#1976d2" }, // Blue text
            }}
            style={{
              flex: "1 1 100%",
              maxWidth: window.innerWidth < 768 ? "400px" : "200px",
            }}
          />
          <TextField
            className={classes.dateField}
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
            variant="outlined"
            color="primary"
            InputProps={{ style: { color: "#1976d2" } }} // Blue text
            max={new Date()}
            style={{
              flex: "1 1 100%",
              maxWidth: window.innerWidth < 768 ? "400px" : "200px",
            }}
          />

          <TextField
            select
            label="Dimensions"
            SelectProps={{
              multiple: true,
              renderValue: (selected) => selected.join(", "),
            }}
            value={selectedDimensions}
            onChange={(e) => setSelectedDimensions(e.target.value)}
            variant="outlined"
            color="primary"
            fullWidth
            style={{
              flex: "1 1 100%",
              maxWidth: window.innerWidth < 768 ? "400px" : "200px",
            }}
          >
            {options.dimensions.map((dim) => (
              <MenuItem key={dim} value={dim}>
                <Checkbox checked={selectedDimensions.includes(dim)} />
                <ListItemText primary={abbr[dim]} />
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Metrics"
            SelectProps={{
              multiple: true,
              renderValue: (selected) => selected.join(", "),
            }}
            value={selectedMetrics}
            onChange={(e) => setSelectedMetrics(e.target.value)}
            variant="outlined"
            color="primary"
            fullWidth
            style={{
              flex: "1 1 100%",
              maxWidth: window.innerWidth < 768 ? "400px" : "200px",
            }}
          >
            {options.metrics.map((metric) => (
              <MenuItem key={metric} value={metric}>
                <Checkbox checked={selectedMetrics.includes(metric)} />
                <ListItemText primary={abbr[metric]} />
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{
              flex: "1 1 100%",
              maxWidth: window.innerWidth < 768 ? "400px" : "200px",
            }}
          >
            Submit
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {data.length > 0 &&
                  Object.keys(data[0]).map((key) => (
                    <TableCell
                      key={key}
                      style={{ color: "#1976d2", fontWeight: "bold" }}
                    >
                      {abbr[key]}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {Object.keys(row).map((key) => (
                    <TableCell key={key} style={{ color: "#000" }}>
                      {row[key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </ThemeProvider>
  );
};

export default UserPanel;
